import { RefObject, useEffect } from 'react'
import tourStyles from '../appSections/Tour/TourOverlay.module.scss'

export const tourHighlightMutationObserver = (
  ref: RefObject<HTMLElement>,                  // pass in the element that has the 'data-tour' attribute
  callback: (isHighlighted: boolean) => void    // use for setting css styles when tour is active
): void => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const isHighlighted = ref.current?.classList.contains(tourStyles.highlight) ?? false

            callback(isHighlighted)
          }
        })
      })

      observer.observe(ref.current, { attributes: true })
      
      return () => observer.disconnect()
    }
  }, [ref, callback])
}