import { unhappyGamesMock } from './unhappyGames'
import { GameDTO } from '../api/dtos/gameDTO'
import { getRandomImageUrl } from './images'

export const gamesMock: GameDTO[] = [
  // -----------------------
  // happy games mocks
  // -----------------------
  {
    titleId: 'happy1',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Albion Online 2',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    platforms: ['MOBILE'],
    tags: ['FEATURED']
  },
  {
    titleId: 'happy2',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Water Parks 🐳',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.nintendo.de/de/mario-kart?srsltid=AfmBOorys2AGn8KuATVOx9vPYu0FVkYHpjWprGiVD88WZpGk-RXyB1fP',
    platforms: ['MOBILE'],
    tags: ['RECOMMENDED']
  },
  {
    titleId: 'happy3',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.nintendo.de/de/mario-kart?srsltid=AfmBOorys2AGn8KuATVOx9vPYu0FVkYHpjWprGiVD88WZpGk-RXyB1fP',
    platforms: ['MOBILE'],
    tags: ['FEATURED']
  },
  {
    'featuredGamesImages': [
      'https://assets-config.iddev.stillfront.com/41_feat_05d7cc3ce2cd01d8a7427f70adb7d1ac.jpg'
    ],
    'gameCardImages': [
      'https://assets-config.iddev.stillfront.com/41_icon_8d0691681530454580f54ac861f37a08.jpg'
    ],
    'gameLaunchUrl': '',
    'platforms': [],
    'recommendedGamesImages': [
      'https://assets-config.iddev.stillfront.com/41_reco_a68bfdbc0fc637ea0886206e01d3f321.png',
      'https://assets-config.iddev.stillfront.com/41_reco_553d86fa83a74d95b9b948325d49ee34.jpg'
    ],
    'tags': [
      'RECOMMENDED',
      'FEATURED'
    ],
    'title': 'Imperia Online',
    'titleId': '41',
    playerIds: null
  },
  // -----------------------
  // unhappy games mocks
  // -----------------------
  ...unhappyGamesMock
]