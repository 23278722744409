import { csrf } from './csrf'
import axios from 'axios'
import { User } from '../contexts/types/user'

const getBaseRequest = (method: string, body: unknown = '') => {
  return {
    method: method,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': csrf.csrfToken
    },
    data: JSON.stringify(body)
  }
}

export const apiUtils = {
  ACCOUNT_BACKEND_BASE_URL: process.env.REACT_APP_ACCOUNT_BACKEND_BASE_URL,
  doAuth() {
    window.location.href = `${this.ACCOUNT_BACKEND_BASE_URL}/oauth2/authorization/account`
  },
  getCsrf() {
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/start`,
      getBaseRequest('GET')
    )
  },
  getUser() {
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/v1/user`,
      getBaseRequest('GET')
    )
  },
  getAllTitles() {
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/v1/games`,
      getBaseRequest('GET')
    )
  },
  updateUser(updatedUser: User) { // @todo is this User supposed to be mapped back to UserDTO
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/v1/user`,
      getBaseRequest('PUT', updatedUser)
    )
  },
  recoverUser() {
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/v1/user/recover`,
      getBaseRequest('PUT')
    )
  },
  deleteAccount() {
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/v1/user`,
      getBaseRequest('DELETE')
    )
  },
  unlinkGameAccount({ playerId, titleId }: {playerId: string, titleId: string}) {
    return axios(
      `${this.ACCOUNT_BACKEND_BASE_URL}/v1/player`,
      getBaseRequest('DELETE', { playerId, titleId })
    )
  }
}
