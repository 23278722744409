import React, { useContext } from 'react'
import styles from './FeaturedGames.module.scss'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'
import gameContext from '../../contexts/GameContext'
import { Hero } from '@stillfront/stillfront-component-library'

const FeaturedGames = () => {
  const { games, loading } = useContext(gameContext)

  const images = (games?.featuredGames ?? [])
    .reduce<{src: string, alt: string}[]>(
      (acc, game) => {
        if (game.featuredGamesImages?.length > 0) {
          acc.push({
            src: game.featuredGamesImages[0],
            alt: game.title || 'Game Image'
          })
        }
        return acc
      }, [])

  if (images.length === 0) images.push({ src: defaultImage, alt: defaultImage })

  return (
    <Hero
      isLoading={loading}
      className={styles.hero}
      imagePosition="center"
      images={images}
      autoplay={true}
      autoplayInterval={5000}
    />
  )
}

export default FeaturedGames
