import React from 'react'
import { isConfirmUnlinkModalProps } from './ModalTypeGuards'
import ConfirmUnlinkModal from '../../components/ConfirmationModal/ConfirmUnlinkModal'
import { useModalContext } from '../../contexts/ModalContext'

const ModalSection = () => {
  const activeModalComponent = getModalComponent()

  return <div>{activeModalComponent !== null && activeModalComponent}</div>
}

const getModalComponent = () => {
  const { modalContent, modalPayload } = useModalContext()

  switch(modalContent) {
    case 'confirmUnlink':
      return isConfirmUnlinkModalProps(modalPayload)
        ? (
          <ConfirmUnlinkModal
            titleId={modalPayload.titleId}
            playerId={modalPayload.playerId}
            title={modalPayload.title}
          />
        )
        : null
    default:
      return null
  }
}

export default ModalSection