import React, { useContext } from 'react'
import styles from './ConfirmUnlinkModal.module.scss'
import { useModalContext } from '../../contexts/ModalContext'
import BannerContext from '../../contexts/BannerContext'
import UserContext from '../../contexts/UserContext'
import { apiUtils } from '../../utils/apiUtils'
import { Button, Modal } from '@stillfront/stillfront-component-library'

export type ConfirmUnlinkModalProps = {
    playerId: string
    titleId: string
    title: string
}

const ConfirmUnlinkModal = ({ playerId, titleId, title }: ConfirmUnlinkModalProps) => {
  const { closeModal } = useModalContext<ConfirmUnlinkModalProps>()
  const { removePlayerIdFromGames } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)

  const handleUnlink = async () => {
    await apiUtils
      .unlinkGameAccount({ playerId, titleId })
      .then(() => {
        removePlayerIdFromGames({ playerId, titleId })
        closeModal()
        setBannerContent('unlinkSuccess')
      })
      .catch(() => {
        closeModal()
        setBannerContent('unlinkError')
      })
  }

  const handleCloseModal = () => closeModal()

  return (
    <Modal isOpen={true} onClose={() => null}>
      <Modal.Title title="Unlink Game" />
      <Modal.Content>
        <p className="body-2">By unlinking your account, you will lose access to your progress tracking, rewards, and many other perks.</p>
        <p className="body-2">Are you sure you want to unlink <strong>{playerId}</strong> from <strong>{title}</strong>?</p>
      </Modal.Content>
      <Modal.Footer>
        <Button theme="light" className={styles.confirmationModalFooterButton} variant="contained" onClick={handleUnlink}>Yes</Button>
        <Button theme="light" className={styles.confirmationModalFooterButton} variant="outlined" onClick={handleCloseModal}>No</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmUnlinkModal
