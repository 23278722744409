import React, { createContext, useEffect, useState, useCallback, ReactNode, Dispatch, SetStateAction } from 'react'
import { apiUtils } from '../utils/apiUtils'
import {
  createRemovePlayerIdFromGames,
  createSetExpired,
  createUpdateAcceptedMarketing,
  createUpdateAcceptedNotifications,
} from './UserMutations'
import { User } from './types/user'
import { AxiosError, AxiosResponse } from 'axios'
import { UserResponse } from '../api/responses/userResponse'
import { mapUserResponseToUser } from '../api/mappers/userMapper'
import { noOpFunctionOverrideError } from '../utils/noOpFunctionOverrideError'

type UserContextProps = {
  userData: User | null
  loading: boolean
  error: Error | null
  removePlayerIdFromGames: (_playerObject: {playerId: string, titleId: string}) => void
  updateAcceptedMarketing: (_state: boolean) => void
  updateAcceptedNotifications: (_state: boolean) => void
  setExpired: (_expired: boolean) => void
}

const UserContext = createContext<UserContextProps>({
  userData: null,
  loading: true,
  error: null,
  removePlayerIdFromGames: () => noOpFunctionOverrideError('removePlayerIdFromGames'),
  updateAcceptedMarketing: () => noOpFunctionOverrideError('updateAcceptedMarketing'),
  updateAcceptedNotifications: () => noOpFunctionOverrideError('updateAcceptedNotifications'),
  setExpired: () => noOpFunctionOverrideError('setExpired')
})

export const UserProvider = ({ children }: {children: ReactNode}) => {
  const [userData, _setUserData] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const setUserData = useCallback(
    (user: User) => user && _setUserData(user),
    []
  ) as Dispatch<SetStateAction<User>>

  const updateAcceptedMarketing = useCallback(createUpdateAcceptedMarketing(setUserData), [setUserData])
  const updateAcceptedNotifications = useCallback(createUpdateAcceptedNotifications(setUserData), [setUserData])
  const removePlayerIdFromGames = useCallback(createRemovePlayerIdFromGames(setUserData), [setUserData])
  const setExpired = useCallback(createSetExpired(setUserData), [setUserData])

  useEffect(() => {
    const fetchUserData = () => {
      apiUtils
        .getUser()
        .then((response: AxiosResponse<UserResponse>) =>
          setUserData(mapUserResponseToUser(response.data)))
        .catch((error: AxiosError) => setError(error))
        .finally(() => setLoading(false))
    }

    fetchUserData()
  }, [])

  const value = {
    userData,
    loading,
    error,
    removePlayerIdFromGames,
    updateAcceptedMarketing,
    updateAcceptedNotifications,
    setExpired
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext