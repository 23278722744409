import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './MyGames.module.scss'
import userContext from '../../contexts/UserContext'
import GameCardGroup from '@stillfront/stillfront-component-library/dist/components/game-card/game-card-group'
import { Game } from '../../contexts/types/game'
import defaultImage from '../../assets/images/stillfront-logo-without-text.svg'
import { GameCardProps } from '@stillfront/stillfront-component-library/dist/components/game-card/game-card'
import { tourHighlightMutationObserver } from '../../utils/tourHighlightMutationObserver'

const mapUserDataToGameCard = (userData: {games: Game[]}): GameCardProps[] =>
  (userData?.games ?? []).map((game) => ({
    image: game.gameCardImages?.[0] ?? defaultImage,
    gameRedirectUrl: game?.gameRedirectUrl,
    titleId: game.titleId,
    title: game.title
  }))

const MyGames = () => {
  const { userData, loading } = useContext(userContext)
  const [games, setGames] = useState<GameCardProps[]>([])
  const [headingColor, setHeadingColor] = useState('white')
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setGames(
      userData?.games
        ? mapUserDataToGameCard(userData)
        : []
    )
  }, [userData?.games, loading])

  tourHighlightMutationObserver(
    containerRef,
    (isHighlighted => setHeadingColor(isHighlighted ? 'black' : 'white'))
  )

  return (
    <div className={styles.myGamesContainer} ref={containerRef} data-tour="firstStep">
      <h3 style={{ color: headingColor }}>My Games</h3>
      <GameCardGroup
        gameCards={games}
        isLoading={loading}
        noItemsMessage="Here will be your games as soon as you link them."
      />
    </div>
  )
}

export default MyGames
