export const getThirtyDaysInTheFuture = (): number => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 30)
  return currentDate.getTime()
}

export const isDateInTheFuture = (timestamp: number) => {
  const dateToCheck = new Date(timestamp)
  const currentDate = new Date()

  return dateToCheck > currentDate
}

export const getDaysUntilDeletion = (timestamp: number) => {
  const expirationDateTimestamp = new Date(timestamp).getTime()
  const differenceInMilliseconds = expirationDateTimestamp - new Date().getTime()
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return Math.ceil(differenceInDays)
}