import React from 'react'
import BaseBanner from '../../appSections/BannerSection/BaseBanner'

const UnlinkErrorBanner = () => {
  return (
    <BaseBanner
      type="error"
      autoClose={true}
      text="The unlinking failed, try again later!"
    />
  )
}

export default UnlinkErrorBanner
