import React, { useState, useEffect, useContext } from 'react'
import BaseBanner from '../../appSections/BannerSection/BaseBanner'
import BannerContext from '../../contexts/BannerContext'
import UserContext from '../../contexts/UserContext'
import { apiUtils } from '../../utils/apiUtils'
import { getDaysUntilDeletion } from '../../utils/dateUtils'

const DeleteSuccessBanner = () => {
  const { userData, setExpired } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null)

  useEffect(() => {
    if (userData?.expirationDate) {
      setDaysRemaining(
        getDaysUntilDeletion(userData.expirationDate)
      )
    }
  }, [userData?.expirationDate])

  const handleRecover = () => {
    apiUtils.recoverUser()
      .then(() => {
        setExpired(false)
        setBannerContent('recoverSuccess')
      })
      .catch((error) => {
        console.error('Error recovering account:', error)
        setBannerContent('recoverError')
      })
  }

  return (
    <BaseBanner
      type="error"
      text={`Your account is scheduled to be deleted in ${daysRemaining} days.`}
      callToActionText="Keep Account"
      handleCallToAction={handleRecover}
    />
  )
}

export default DeleteSuccessBanner
