import React from 'react'
import FeaturedGames from '../../components/FeaturedGames/FeaturedGames'
import MyGames from '../../components/MyGames/MyGames'
import RecommendedGames from '../../components/RecommendedGames/RecommendedGames'
import styles from './HomeContent.module.scss'

const HomeContent = () => {
  return (
    <div className={styles.container}>
      <FeaturedGames />
      <div className={['stillfront-container-max', styles.contentContainer].join(' ')}>
        <MyGames />
        <RecommendedGames />
      </div>
    </div>
  )
}

export default HomeContent