import { Dispatch, SetStateAction } from 'react'
import { getThirtyDaysInTheFuture } from '../utils/dateUtils'
import { User } from './types/user'

type SetUserData = Dispatch<SetStateAction<User>>

export const createUpdateAcceptedMarketing = (setUserData:  SetUserData) => (state: boolean) => {
  setUserData((prevUserData) => ({
    ...prevUserData,
    acceptedMarketing: state,
  }))
}

export const createUpdateAcceptedNotifications = (setUserData: SetUserData) => (state: boolean) => {
  setUserData((prevUserData) => ({
    ...prevUserData,
    acceptedNotifications: state,
  }))
}

export const createRemovePlayerIdFromGames = (setUserData: SetUserData) => ({ playerId, titleId }: {playerId: string, titleId: string}) => {
  setUserData((prevUserData) => {
    const newGames = prevUserData.games.map((game) =>
      game.titleId === titleId
        ? { ...game, playerIds: game.playerIds.filter((pId) => pId !== playerId) }
        : game
    )
    return { ...prevUserData, games: newGames }
  })
}

export const createSetExpired = (setUserData: SetUserData) => (expired: boolean) => {
  setUserData((prevUserData) => ({
    ...prevUserData,
    expirationDate: expired ? getThirtyDaysInTheFuture() : null,
    expired: expired ? 1 : 0
  }))
}