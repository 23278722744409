import React, { ReactNode } from 'react'
import { BannerProvider } from './BannerContext'
import { ModalProvider } from './ModalContext'
import { UserProvider } from './UserContext'
import { GameProvider } from './GameContext'
import { TourProvider } from './TourContext'
import { tourSteps } from '../appSections/Tour/TourSteps'


const Providers = ({ children }: {children: ReactNode}) => {
  return (
    <UserProvider>
      <GameProvider>
        <BannerProvider>
          <ModalProvider>
            <TourProvider steps={tourSteps}>
              {children}
            </TourProvider>
          </ModalProvider>
        </BannerProvider>
      </GameProvider>
    </UserProvider>
  )
}

export default Providers