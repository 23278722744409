import React, { createContext, useContext, useState, ReactNode } from 'react'
import { TourOverlay } from '../appSections/Tour/TourOverlay'
import { TourStep } from '../appSections/Tour/types'

type TourContextType = {
  steps: TourStep[]
  currentStepIndex: number
  isTourActive: boolean
  startTour: () => void
  nextStep: () => void
  prevStep: () => void
  endTour: () => void
}

export const TourContext = createContext<TourContextType | undefined>(undefined)

export const TourProvider: React.FC<{children: ReactNode, steps: TourStep[]}> = ({ children, steps }) => {
  const [isTourActive, setIsTourActive] = useState(false)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const startTour = () => {
    setIsTourActive(true)
    setCurrentStepIndex(0)
  }

  const nextStep = () => {
    currentStepIndex < steps.length - 1
      ? setCurrentStepIndex(currentStepIndex + 1)
      : endTour()
  }

  const prevStep = () => {
    currentStepIndex > 0
      && setCurrentStepIndex(currentStepIndex - 1)
  }

  const endTour = () => setIsTourActive(false)

  return (
    <TourContext.Provider value={{
      steps,
      currentStepIndex,
      isTourActive,
      startTour,
      nextStep,
      prevStep,
      endTour,
    }}>
      {children}
      {isTourActive && <TourOverlay />}
    </TourContext.Provider>
  )
}

export const useTour = (): TourContextType => {
  const context = useContext(TourContext)
  if (!context) {
    throw new Error('useTour must be used within a TourProvider')
  }
  return context
}