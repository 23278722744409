import { GameDTO, PlatformsDTO } from '../dtos/gameDTO'
import { Game, Games, Platforms } from '../../contexts/types/game'

export const mapGames = (games: GameDTO[]): Games => {
  const recommendedGames: Game[] = []
  const featuredGames: Game[] = []

  games.forEach((game) => {
    if (game.tags?.includes('RECOMMENDED')) {
      recommendedGames.push(mapGameDtoToGame(game))
    }
    if (game.tags?.includes('FEATURED')) {
      featuredGames.push(mapGameDtoToGame(game))
    }
  })

  return { recommendedGames, featuredGames }
}

// @todo add validation
export const mapGameDtoToGame = (game: GameDTO): Game => ({
  titleId: game.titleId ?? '',
  playerIds: game.playerIds ?? [],
  title: game.title ?? '',
  recommendedGamesImages: filterNullImages(game.recommendedGamesImages?? []),
  featuredGamesImages: filterNullImages(game.featuredGamesImages ?? []),
  gameCardImages: filterNullImages(game.gameCardImages ?? []),
  gameRedirectUrl: game.gameLaunchUrl,
  subtitle: '',
  platforms: getPlatformsFromTags(game.platforms) ?? null
})

const filterNullImages = (images: (string | null)[]): string[] => {
  return images.filter((image): image is string => image !== null)
}

const getPlatformsFromTags = (tags: PlatformsDTO): Platforms => {
  return {
    apple: !!tags?.includes('MOBILE'),
    windows: !!tags?.includes('WINDOWS'),
    google: !!tags?.includes('MOBILE'),
    steam: !!tags?.includes('STEAM'),
  }
}