import React, { ReactNode } from 'react'
import { Route, Routes } from 'react-router-dom'
import styles from './ContentSection.module.scss'
import NavigationBar from '../NavigationBar/NavigationBar'
import AccountDelete from '../../pages/AccountDelete/AccountDelete'
import AccountSettings from '../../pages/AccountSettings/AccountSettings'
import HomeContent from '../../pages/HomeContent/HomeContent'
import ManageAccounts from '../../pages/ManageAccounts/ManageAccounts'
import PrivacyAndSettings from '../../pages/PrivacyAndSettings/PrivacyAndSettings'

export const ROUTES = {
  HOME: '/',
  ACCOUNT: '/account',
  PRIVACY: '/privacy-and-settings',
  MANAGE_ACCOUNTS: '/manage-accounts',
  DELETE_ACCOUNT: '/delete-account',
}

const ContentSection = ({ children }: {children: ReactNode}) => {
  return (
    <div className={styles.contentSection}>
      {children}
      <NavigationBar />
      <Routes>
        <Route path={ROUTES.HOME} element={<HomeContent/>}/>
        <Route path={ROUTES.ACCOUNT} element={<AccountSettings/>}/>
        <Route path={ROUTES.PRIVACY} element={<PrivacyAndSettings/>}/>
        <Route path={ROUTES.MANAGE_ACCOUNTS} element={<ManageAccounts/>}/>
        <Route path={ROUTES.DELETE_ACCOUNT} element={<AccountDelete />}/>
      </Routes>
    </div>
  )
}

export default ContentSection