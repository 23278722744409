import React, { useContext } from 'react'
import { usePreservingNavigate } from '../../utils/preservingNavigation'
import styles from './AccountDelete.module.scss'
import { ROUTES } from '../../appSections/ContentSection/ContentSection'
import warningIcon from '../../assets/images/warning.svg'
import BannerContext from '../../contexts/BannerContext'
import UserContext from '../../contexts/UserContext'
import { apiUtils } from '../../utils/apiUtils'
import { Button } from '@stillfront/stillfront-component-library'

const AccountDelete = () => {
  const { userData, setExpired } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)
  const navigate = usePreservingNavigate()

  const handleDelete = async () => {
    apiUtils.deleteAccount()
      .then(() => setExpired(true))
      .catch(error => {
        console.error('Error deleting account:', error)
        setBannerContent('deleteError')
      })
  }

  const handleBack = () => navigate(ROUTES.PRIVACY)

  return (
    <div className={`stillfront-content-container ${styles.container}`}>
      <div className={styles.textContainer}>
        <img
          src={warningIcon}
          className={styles.warningIcon}
          alt="Warning"
        />
        <p className="body-1">
          You are about to delete your account. If you proceed, your account will be deleted within <span className="fw-bold">30 days</span>. You may lose your game progress.
        </p>
      </div>
      <div className={styles.buttonGroup}>
        <Button variant="delete" onClick={handleDelete} disabled={!!userData && userData.expired === 1}>
          Delete Account
        </Button>
        <Button variant="contained" onClick={handleBack}>
          Go Back
        </Button>
      </div>
    </div>
  )
}

export default AccountDelete
