import { worker } from '../mocks/handlers'
import { csrf } from './csrf'
import { apiUtils } from './apiUtils'

export const checkSession = async (): Promise<void> => {
  if (process.env.REACT_APP_MOCK === 'true') {
    await worker.start({ onUnhandledRequest: 'bypass' })
  }

  const csrfToken = await csrf.fetchAndStoreCsrfToken()

  if (!csrfToken) {
    console.log('Session check failed. Redirecting to auth...')
    apiUtils.doAuth()
  }
}