import { getRandomImageUrl } from './images'
import { GameDTO } from '../api/dtos/gameDTO'

export const unhappyGamesMock: GameDTO[] = [
  {
    titleId: 'unhappy1',
    playerIds: [], // no player ids
    title: 'Albion Online 2 - Orcs and fine dining 🐙',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    platforms: ['MOBILE'],
    tags: ['FEATURED']
  },
  {
    titleId: 'unhappy2',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8 Deluxe',
    recommendedGamesImages: [getRandomImageUrl()],
    featuredGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    platforms: null, // platforms is null
    tags: ['RECOMMENDED']
  },
  {
    titleId: 'unhappy3',
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Albion Online 2 - Orcs and fine dining 🐙',
    featuredGamesImages: null,
    recommendedGamesImages: [getRandomImageUrl()],
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: null, // no gameLaunchUrl
    platforms: ['MOBILE'],
    tags: null // tags is null
  },
  {
    titleId: null, // titleId is null
    playerIds: ['randomGamerTag#42069', 'LoreWizard#1991'],
    title: 'Super Marius Kart 8 Deluxe',
    featuredGamesImages: [getRandomImageUrl()],
    recommendedGamesImages: null,
    gameCardImages: [getRandomImageUrl()],
    gameLaunchUrl: 'https://store.steampowered.com/app/2510960/Temtem_Swarm/',
    platforms: ['MOBILE'],
    tags: ['FEATURED']
  }
]