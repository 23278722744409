import { TourStep } from './types'
import { ROUTES } from '../ContentSection/ContentSection'

export const tourSteps: TourStep[] = [
  {
    selector: 'firstStep',
    title: 'Explore Your Games',
    content: 'This section displays all the games you\'ve linked to your account. Tap on any game to dive straight into the action!',
    placement: 'bottom',
    path: ROUTES.HOME
  },
  {
    selector: 'secondStep',
    title: 'Manage Your Account',
    content: 'Access your account settings here to update your profile, manage linked game accounts, or adjust your privacy preferences. This is your one-stop hub for personalizing your experience.',
    placement: 'bottom',
    path: ROUTES.HOME
  }
]