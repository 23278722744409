import React, { useContext, useEffect, useState } from 'react'
import styles from './BaseBanner.module.scss'
import successIcon from '../../assets/images/success.svg'
import warningIcon from '../../assets/images/warning-light.svg'
import BannerContext from '../../contexts/BannerContext'
import { Button } from '@stillfront/stillfront-component-library'

export type BaseBannerProps = {
  type: 'success' | 'warning' | 'error' | 'info'
  text: string
  handleCallToAction?: () => void
  callToActionText?: string
  autoClose?: boolean
}

const BaseBanner = ({ type, text, handleCallToAction, callToActionText, autoClose }: BaseBannerProps) => {
  const [hideBanner, setHideBanner] = useState(false)
  const { setBannerContent } = useContext(BannerContext)

  useEffect(() => {
    autoClose && setTimeout(() => setHideBanner(true), 5000)
    autoClose && setTimeout(() => setBannerContent(null), 5500)
  }, [])
    
  const icon = type === 'success' ? successIcon : warningIcon

  const bannerClasses = `${styles.banner} ${styles[type + 'Banner']} ${hideBanner ? styles.hide : ''}`
  const iconClasses = `${styles.icon} ${styles[type + 'Icon']}`

  return (
    <div className={styles.bannerContainer}>
      <div className={bannerClasses}>
        <div className={styles.bannerContent}>
          <img
            src={icon}
            className={iconClasses}
            alt={type}
          />
          <p className={`body-1 ${styles.bannerContentText}`}>{text}</p>
        </div>
        {handleCallToAction && callToActionText && (
          <Button
            className={styles.bannerActionButton}
            variant="contained"
            size="small"
            onClick={handleCallToAction}
          >{callToActionText}</Button>
        )}
      </div>
    </div>
  )
}

export default BaseBanner
