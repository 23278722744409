import React, { createContext, useState, ReactNode } from 'react'
import { noOpFunctionOverrideError } from '../utils/noOpFunctionOverrideError'

type BannerContent = 'deleteSuccess' | 'deleteError' | 'recoverSuccess' | 'recoverError' | 'unlinkSuccess' | 'unlinkError' | 'genericError' | null

type BannerContextProps = {
  bannerContent: BannerContent
  setBannerContent: (_bannerContent: BannerContent) => void
}

const BannerContext = createContext<BannerContextProps>({
  bannerContent: null,
  setBannerContent: () => noOpFunctionOverrideError('setBannerContent'),
})

export const BannerProvider = ({ children }: {children: ReactNode}) => {
  const [bannerContent, setBannerContent] = useState<BannerContent>(null)

  return (
    <BannerContext.Provider value={{ bannerContent, setBannerContent }}>
      {children}
    </BannerContext.Provider>
  )
}

export default BannerContext