import { useNavigate, useLocation } from 'react-router-dom'
import { NavigateOptions } from 'react-router/dist/lib/context'

export const usePreservingNavigate = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (path: string, options?: NavigateOptions) => {
    navigate(`${path}${location.search}`, options)
  }
}