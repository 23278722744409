import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@stillfront/stillfront-component-library/dist/main.scss'
import { useTour } from './contexts/TourContext'
import { useContext, useEffect } from 'react'
import UserContext from './contexts/UserContext'
import BannerSection from './appSections/BannerSection/BannerSection'
import ContentSection, { ROUTES } from './appSections/ContentSection/ContentSection'
import ModalSection from './appSections/ModalSection/ModalSection'
import CookieConsent from './components/CookieConsent/CookieConsent'
import Providers from './contexts/Providers'
import { checkSession } from './utils/session'
import { Footer, Header } from '@stillfront/stillfront-component-library'
import { usePreservingNavigate } from './utils/preservingNavigation'
import { getCleanUserName } from './utils/userNameUtils'

const AppLayout = () => {
  const { startTour } = useTour()
  const { loading, userData } = useContext(UserContext)
  const navigate = usePreservingNavigate()
  const isActiveTour = new URLSearchParams(location.search).get('tour')

  useEffect(() => {
    !loading && isActiveTour && startTour()
  }, [loading])

  window.addEventListener('pageshow', async (event) =>
    event.persisted && await checkSession()
  )

  const handleAvatarClick = () => navigate(ROUTES.ACCOUNT)
  const headerAvatar = document.getElementById('stillfront-cl-avatar')
  headerAvatar?.setAttribute('data-tour', 'secondStep')

  return (
    <>
      {isActiveTour && <div className="header-tour-overlay" />}
      <div className="app dark">
        <Header
          isLoggedIn={true}
          onLogoClick={() => window.location.href = '/'}
          onAvatarClick={handleAvatarClick}
          userName={getCleanUserName(userData?.userName ?? '')}
        />
        <BannerSection/>
        <ContentSection>
          <ModalSection/>
        </ContentSection>
        <CookieConsent/>
        <Footer style={{ backgroundColor: 'transparent' }} />
      </div>
    </>
  )
}

const App = () => {
  console.log('Commit: ', process.env.REACT_APP_COMMIT_SHA)

  return (
    <Router>
      <Providers>
        <AppLayout/>
      </Providers>
    </Router>
  )
}

export default App
