import React from 'react'
import BaseBanner from '../../appSections/BannerSection/BaseBanner'

const UnlinkSuccessBanner = () => {
  return (
    <BaseBanner
      type="success"
      autoClose={true}
      text="The game has been unlinked successfully!"
    />
  )
}

export default UnlinkSuccessBanner
