import { User } from '../../contexts/types/user'
import { UserResponse } from '../responses/userResponse'
import { mapGameDtoToGame } from './gameMapper'

export const mapUserResponseToUser = (userResponse: UserResponse): User => ({
  games: userResponse?.games?.length
    ? userResponse.games.map(mapGameDtoToGame)
    : [],
  acceptedMarketing: userResponse.user.acceptedMarketing,
  acceptedNotifications: userResponse.user.acceptedNotifications,
  creationDate: userResponse.user.creationDate,
  deletionDate: userResponse.user.deletionDate,
  expirationDate: userResponse.user.expirationDate,
  expired: userResponse.user.expired,
  language: userResponse.user.language,
  sfId: userResponse.user.sfId,
  userName: userResponse.user.userName
})
