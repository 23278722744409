import React, { createContext, useEffect, useState, ReactNode } from 'react'
import { apiUtils } from '../utils/apiUtils'
import { mapGames } from '../api/mappers/gameMapper'
import { Games } from './types/game'
import { AxiosError, AxiosResponse } from 'axios'
import { GamesResponse } from '../api/responses/gamesResponse'

type GameContextProps = {
  games: Games
  loading: boolean
  error: Error | null
}

const GameContext = createContext<GameContextProps>({
  games: {
    recommendedGames: [],
    featuredGames: []
  },
  loading: true,
  error: null,
})

export const GameProvider = ({ children }: { children: ReactNode }) => {
  const [games, setGames] = useState<Games>({ recommendedGames: [], featuredGames: [] })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchGameData = () => {
      apiUtils
        .getAllTitles()
        .then((response: AxiosResponse<GamesResponse>) =>
          response.data.games && setGames(mapGames(response.data.games))
        )
        .catch((error: AxiosError) => setError(error))
        .finally(() => setLoading(false))
    }

    fetchGameData()
  }, [])

  const value = {
    games,
    loading,
    error
  }

  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  )
}

export default GameContext