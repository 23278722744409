import React from 'react'
import { Button, Checkbox } from '@stillfront/stillfront-component-library'
import '@stillfront/stillfront-component-library/dist/main.scss'
import { Link } from 'react-router-dom'
import { usePreservingNavigate } from '../../utils/preservingNavigation'
import styles from './PrivacyAndSettings.module.scss'
import { apiUtils } from '../../utils/apiUtils'
import { useContext } from 'react'
import UserContext from '../../contexts/UserContext'
import BannerContext from '../../contexts/BannerContext'
import { ROUTES } from '../../appSections/ContentSection/ContentSection'

const PrivacyAndSettings = () => {
  const { userData, updateAcceptedMarketing, updateAcceptedNotifications, loading } = useContext(UserContext)
  const { setBannerContent } = useContext(BannerContext)
  const navigate = usePreservingNavigate()
  const handleDelete = () => navigate(ROUTES.DELETE_ACCOUNT)

  const handleNotificationsToggle = (checked: boolean) => {
    const previousValue = userData?.acceptedNotifications ?? false
    updateAcceptedNotifications(checked)
    userData && apiUtils.updateUser(({ ...userData, acceptedNotifications: checked }))
      .catch((error) => {
        console.error('Failed to update terms: ', error)
        setBannerContent('genericError')
        updateAcceptedNotifications(previousValue)
      })
  }

  const handleMarketingToggle = (checked: boolean) => {
    const previousValue = userData?.acceptedMarketing ?? false
    updateAcceptedMarketing(checked)
    userData && apiUtils.updateUser({ ...userData, acceptedMarketing: checked })
      .catch((error) => {
        console.error('Failed to update marketing: ', error)
        updateAcceptedMarketing(previousValue)
        setBannerContent('genericError')
      })
  }

  return (
    <div className={`stillfront-content-container ${styles.container}`}>
      <Checkbox
        checked={userData ? userData.acceptedMarketing : false}
        onChange={handleMarketingToggle}
        label="I want to receive news, offers, and gifts via email"
        isLoading={loading}
      />
      <Checkbox
        checked={userData ? userData.acceptedNotifications : false}
        onChange={handleNotificationsToggle}
        label="I want to receive tailored ads and recommendations"
        isLoading={loading}
      />
      <div>
        By using our service, you agree to our Privacy and Terms and Conditions. For comprehensive details,
        follow&nbsp;
        <Link target="_blank" to="https://info.account.stillfront.com/terms/index.html" className={styles.link}>this link.</Link>
      </div>
      <Button variant="outlined" onClick={handleDelete}>Delete Account</Button>
    </div>
  )
}

export default PrivacyAndSettings
