import React from 'react'
import BaseBanner from '../../appSections/BannerSection/BaseBanner'

const DeleteErrorBanner = () => {
  return (
    <BaseBanner
      type="error"
      autoClose={true}
      text="The account deletion failed, try again later!"
    />
  )
}

export default DeleteErrorBanner
